import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { Faq } from "../components/partials/faq/faq"
import { FeaturesPartial } from "../components/partials/features/featuresPartial"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

const Feature = ({ data }) => {
  const { feature, homepages } = data.strapi;
  const { language, meta, featureItem, faqHeading, faqsItem, title } = feature;
  const { header, footer } = homepages[0];

  const disptach = useContext(GlobalDispatchContext);
  useEffect(() => {
    disptach({ type: "SET_LANGUAGE", language })
  })

  return (
    <Layout header={header} footer={footer}>
      <SEO title={meta.title} description={meta.description} />
      <FeaturesPartial features={featureItem} title={title} />
      <Faq faqHeading={faqHeading} faqItems={faqsItem} />
    </Layout>
  )
}

export default Feature

export const pageQuery = graphql`
  query Feature($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      feature(id: $id) {
        language
        title
        featureItem {
          heading
          bigHeading
          description
          image {
            url
          }
        }
        faqHeading
        faqsItem {
          question
          answer
        }
        meta {
          title
          description
        }
      }
      homepages(where: $language) {
        header {
          logo {
            url
          }
          links {
            label
            slug
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
      }
    }
  }
`
